import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
	fillColor?: string;
	type?: 'default' | 'chevron';
}

export const DisclosureIcon: React.FC<IProps> = props => {
	const { className, fillColor, type } = props;
	return (
		<SvgIcon className={`disclosure-icon ${className || ''}`} height={8} width={type === 'chevron' ? 13 : 8}>
			<g fill='none' fillRule='evenodd'>
				{type === 'chevron' ? (
					<polygon
						fill={fillColor || '#858585'}
						fillRule='evenodd'
						points='10.412 -.823 8.824 -2.412 2.412 4 8.824 10.412 10.412 8.824 5.589 4'
						transform='rotate(-90 6.412 4)'
					/>
				) : (
					<>
						<polygon fill={fillColor || '#767676'} points='4 2 8 6 0 6' transform='matrix(0 -1 -1 0 8 8)' />
						<rect width='8' height='8' />
					</>
				)}
			</g>
		</SvgIcon>
	);
};
