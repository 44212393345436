import * as Api from '@ViewModels';
import { AdminUserSessionContext } from '../viewmodels/AdminViewModels';

export interface IAdminUserSessionComponentProps {
	userSession?: AdminUserSessionContext;
}

export interface IIntegrationUser extends Api.IIntegrationUserMapping {
	levitateUser?: Api.IUser;
}

export interface IAddEmployeeResult {
	sendWelcomeEmail?: boolean;
	user?: Api.IUser;
}

export interface IContactsAssignIntegrationOwnerRequest {
	integrationOwnerId: string;
	newOwnerId: string;
}

export const MastheadPortalId = 'masthead-portal-destination';

export interface IRevertImportResult {
	contactsDeleted: number;
	companiesDeleted: number;
	jobDeleted: boolean;
}

export interface IPlatformError {
	appUrl: string;
	context: string;
	error_class: string;
	last_seen: string;
	message: string;
	url: string;
}

export type UserStatusAction = 'reactivate' | 'deactivate' | 'makeAdmin' | 'removeAdmin';

export interface IAggregateActiveUse {
	activeAccounts: number;
	activeAccountsPercentage: number;
	changedToRedAccounts: number;
	changedToRedAccountsPercentage: number;
	grayAccounts: number;
	grayAccountsPercentage: number;
	greenAccounts: number;
	greenAccountsPercentage: number;
	industries: object | null;
	industry: string | null;
	orangeAccounts: number;
	orangeAccountsPercentage: number;
	redAccounts: number;
	redAccountsPercentage: number;
	totalAccounts: number;
	user: Api.IUserReference | null;
	yellowAccounts: number;
	yellowAccountsPercentage: number;
}

export interface IActiveUseSummary {
	byCustomerSuccess: IAggregateActiveUse[];
	byIndustry: IAggregateActiveUse[];
	bySalesRep: IAggregateActiveUse[];
	calculatedDate: Date;
	overall: IAggregateActiveUse;
}

export interface ICampaignReportAdminRequest {
	accountId?: string;
	endDate?: Date;
	startDate?: Date;
}

export interface IRenewalProbabilityByIndustry {
	industry: string;
	percentage: number;
}

export interface IUpToDateRenewalProbabilityByIndustry {
	industry: string;
	percentageUpToDate: number;
}

export interface INoSaleByIndustry {
	amount: number;
	industry: string;
}

export interface IMonthlyRecurringRevenueSummary {
	actualChurn: number;
	byIndustry: IRenewalProbabilityByIndustry[];
	noSaleByIndustry: INoSaleByIndustry[];
	noSaleTotal: number;
	projectedChurn: number;
	totalMRR: number;
	totalRenewalPercent: number;
	upToDateByIndustry: IUpToDateRenewalProbabilityByIndustry[];
	upToDateProjection: number;
	upToDateRenewal: number;
}

export interface IActiveUseDailySummaryByAccount {
	accountId: string;
	calculatedDate: string;
	companyName: string;
	customerSuccessId: string;
	customerSuccessName: string;
	previousStatusColor: string;
	statusChanged: boolean;
	usage: Api.IAccountUsage;
}

export type BulkEmailApprovalUsersSelectMode = 'one' | 'many';

export interface ICampaignEmailRequest {
	approvalContent?: Api.IRawRichTextContentState;
	contactsFilter?: Api.IContactsFilterRequest;
	content: Api.IRawRichTextContentState;
	contentCalendarSuggestionId?: string;
	startDate: string;
	subject: string;
	templateReference?: Api.ITemplateReference;
	userIds: string[];
}

export interface ITemplatesIndustryWithCategories {
	industry: Api.Industry;
	categoryNames?: string[];
}

export interface ISystemEmailTemplateWithStatuses {
	statuses?: Api.ITemplateStatus[];
	template?: Api.ITemplate;
	socialMedia?: Api.IEmbeddedSocialMediaTemplateContent;
}

export interface IHandwrittenCardTemplateWithStatuses {
	statuses?: Api.ITemplateStatus[];
	template?: Api.IHandwrittenCardTemplate;
}

export interface IBaseSystemTemplate<T extends Api.IBaseResourceModel = Api.IBaseResourceModel> {
	template: T;
	socialMedia?: Api.IEmbeddedSocialMediaTemplateContent;
	industries: Api.IDictionary<Api.TemplateStatus>;
}

export interface ISystemEmailTemplate<T extends Api.ITemplate = Api.ITemplate> extends IBaseSystemTemplate<T> {
	template: T;
	socialMedia?: Api.IEmbeddedSocialMediaTemplateContent;
}

export type ISystemAutomationTemplate<T extends Api.IAutomationTemplateReference = Api.IAutomationTemplateReference> =
	IBaseSystemTemplate<T>;

export interface IAccountSnapshot {
	account: Api.IAccount;
	accountCancelled?: boolean;
	pendingCancellation?: Api.ICancellationRequest;
	accountDeleted?: boolean;
	currentBillingStatus: string;
	currentSummaryNoteId: string;
	currentCsmNoteId: string;
	id: string;
	lastUpdated: Date;
	// In the format of YYYY-MM
	renewalMonth: string;
	renewalPdfSent: Date;
	updatedRenewalProbability: number;
}

export interface IBulkEmailHistoricalAttempt extends Api.IBaseResourceModel {
	bulkEmailId: string;
	successes: number;
	failures: number;
	errorMessages: string[];
}

export interface IBulkEmailHistoricalTimeBlock {
	block: Date;
	successes: number;
	failures: number;
	errorMessages: string[];
}

export interface IBulkEmailHistoryDataPoint {
	ErrorMessages?: string[];
	Failures: number;
	Hour: string;
	Successes: number;
}

export interface IUserRollingSendLimits {
	hourlyLimit: number;
	dailyLimit: number;
}

export interface IBulkEmailHistoricalReport {
	intervalInMinutes: number;
	blocks: IBulkEmailHistoricalTimeBlock[];
	limits: IUserRollingSendLimits;
}

export enum AggregationIdType {
	Account = 0,
	User,
	Campaign,
}

export interface ICustomerNote {
	note?: Api.INote;
}

export interface ICustomerMeeting extends ICustomerNote {
	durationInMinutes?: number;
	metWith?: InternalMeetingNoteType;
	meetingStatus?: string;
	meetingTags?: string[];
	meetingType?: string;
}

export enum InternalMeetingNoteType {
	CSM = 'CSM',
	Sales = 'Sales',
	Summary = 'Summary',
	Websites = 'Websites',
}

export interface IEditCampaignRequest<T extends Api.CampaignViewModel = Api.CampaignViewModel> {
	campaign?: T;
	campaignGroup?: T[];
	impersonationContext?: Api.IImpersonationContext;
	referrer?: string;
}

export interface IEditHtmlNewsletterRequest extends IEditCampaignRequest {
	impersonationContext?: Api.IImpersonationContext;
	type: string;
	template: Api.ITemplate;
}

export enum CampaignType {
	Email = 'Email',
	Social = 'Social',
	htmlNewsletter = 'htmlNewsletter',
	Blog = 'Blog',
}
