import equal from 'fast-deep-equal';
import moment from 'moment';
import * as ViewModels from '.';
import * as Api from './sdk';
import { UserSessionContext, ViewModel } from './viewModels/index';

/**
 * Useful when api returns a contact that isn't in the system. The contactID will be '<resolveByEmail>'.
 * (case-insensitive)
 */
export const ResolveByEmailRegExp = new RegExp('resolveByEmail-', 'i');

/** Removes keys where object[key] = null/undefined/or empty string */
export const removeEmptyKvpFromObject = (object: any) => {
	if (object) {
		Object.keys(object).forEach(key => {
			const value = object[key];
			if (value === null || value === undefined || (typeof value === 'string' && !value)) {
				delete object[key];
			}
		});
	}
	return object;
};

export const getPrimaryEmailAddress = (principal: Api.IPrincipal) => {
	let primaryEmailAddress: Api.EmailAddress;
	if (principal) {
		// @ts-ignore
		primaryEmailAddress = principal.primaryEmail ? principal.primaryEmail : null;
		if (!primaryEmailAddress || !primaryEmailAddress.value) {
			// @ts-ignore
			primaryEmailAddress = null;

			// try as a contact
			if (Object.prototype.hasOwnProperty.call(principal, 'emailAddresses')) {
				const contact = principal as Api.IContact;
				// @ts-ignore
				primaryEmailAddress =
					!!contact.emailAddresses && contact.emailAddresses.length > 0
						? contact.emailAddresses.find(x => !!x.value)
						: null;
			}
		}
	}

	// @ts-ignore
	return primaryEmailAddress;
};

export const getDisplayName = (principal: Api.IPrincipal, short = false) => {
	if (principal) {
		if (!!principal.firstName && !!principal.lastName) {
			if (principal.lastName.toLowerCase().trim() === 'n/a') {
				return principal.firstName;
			}
			return `${principal.firstName} ${short ? `${principal.lastName.charAt(0)}.` : principal.lastName}`;
		}

		const primaryEmail = getPrimaryEmailAddress(principal);
		if (primaryEmail) {
			return primaryEmail.value;
		}
	}

	return '';
};

export const getPhoneNumber = (entity: ViewModels.TextRecipientViewModel, toNumbers: ViewModels.ITextRecipient[]) => {
	if (entity && entity.selectedPhoneNumber) {
		return entity.selectedPhoneNumber?.metadata?.standard;
	} else if (entity && entity.phoneNumbers) {
		if (entity.phoneNumbers.length === 1) {
			return entity.phoneNumbers[0]?.metadata?.standard;
		} else if (entity.phoneNumbers.length > 1) {
			// @ts-ignore
			return entity.phoneNumbers.find(x => toNumbers?.find(y => y.number.standard === x.metadata.standard))?.metadata
				?.standard;
		}
	}
};

export const getDisplayNameWithEmailFirst = (principal: Api.IPrincipal, short = false) => {
	if (!!principal && !!principal.primaryEmail) {
		return principal.primaryEmail.value;
	}

	return getDisplayName(principal, short);
};

export const getDisplayNameWithLastNameFirst = (principal: Api.IPrincipal, short = false) => {
	if (principal) {
		if (!!principal.firstName && !!principal.lastName) {
			if (principal.lastName.toLowerCase().trim() === 'n/a') {
				return principal.firstName;
			}
			return `${principal.lastName}, ${short ? `${principal.firstName.charAt(0)}.` : principal.firstName}`;
		}

		const primaryEmail = getPrimaryEmailAddress(principal);
		if (primaryEmail) {
			return primaryEmail.value;
		}
	}

	return '';
};

/**
 * @param entity IEntity model
 * @returns Formatted display name for the given entity
 */
export const getEntityDisplayName = (entity: Api.IEntity) => {
	if (entity) {
		return Object.prototype.hasOwnProperty.call(entity, 'firstName') ||
			Object.prototype.hasOwnProperty.call(entity, 'lastName') ||
			Object.prototype.hasOwnProperty.call(entity, 'primaryEmail')
			? getDisplayName(entity as Api.IPrincipal)
			: entity.companyName;
	}
	return '';
};

export interface IRichContentEntityViewModelReferences {
	companyRefs: Api.IRichContentEntityReference<ViewModels.CompanyViewModel>[];
	contactRefs: Api.IRichContentEntityReference<ViewModels.ContactViewModel>[];
	userRefs: Api.IRichContentEntityReference<Api.IUser>[];
}

export const getReferenceEntityViewModels = (
	userSession: UserSessionContext,
	richContent?: Api.IRichContent
): IRichContentEntityViewModelReferences => {
	const refs: IRichContentEntityViewModelReferences = {
		companyRefs: [],
		contactRefs: [],
		// @ts-ignore
		// @ts-ignore
		userRefs: richContent.referencedEntities.users || [],
	};

	if (richContent) {
		// @ts-ignore
		refs.contactRefs = (richContent.referencedEntities.contacts || []).map(x => {
			const ref: Api.IRichContentEntityReference<ViewModels.ContactViewModel> = {
				entity: new ViewModels.ContactViewModel(userSession, x.entity),
				method: x.method,
			};
			return ref;
		});

		// @ts-ignore
		refs.companyRefs = (richContent.referencedEntities.companies || []).map(x => {
			const ref: Api.IRichContentEntityReference<ViewModels.CompanyViewModel> = {
				entity: new ViewModels.CompanyViewModel(userSession, x.entity),
				method: x.method,
			};
			return ref;
		});
	}

	return refs;
};

export const getDefaultVisibility = (user: Api.IUser) => {
	if (!!user && !!user.userPreferences && !!user.userPreferences.defaultGroup) {
		return user.userPreferences.defaultGroup;
	}

	return 'all';
};

/**
 * Helper method for setting property values of an object. Useful if you need to set a private/protected value that you
 * know exists.
 *
 * @param object Object to work on
 * @param propertyName Name of property to modify
 * @param value New value to set
 * @param setOnlyIfObjectHasProperty Flag controlling safty check (default = true)
 */
export const setObjectPropertyValue = (
	object: any,
	propertyName: string,
	value: any,
	setOnlyIfObjectHasProperty = true
) => {
	if (!!object && !!propertyName) {
		if (!!setOnlyIfObjectHasProperty && !Object.prototype.hasOwnProperty.call(object, propertyName)) {
			return;
		}
		object[propertyName] = value;
	}
};

/** Helper method to set busy states */
export const setViewModelBusy = (viewModel: ViewModel, busy: boolean) => {
	if (busy !== viewModel.isBusy) {
		setObjectPropertyValue(viewModel, 'busy', busy);
	}
};

export interface ISortedContactFilterCriteria {
	compound: Api.IContactFilterCriteria[];
	filters: Api.IContactFilterCriteria[];
	searches: Api.IContactFilterCriteria[];
}

/**
 * Adds @param b to @param a
 *
 * @returns New collection that's the combination of filter collections and does a de-dupe as well
 */
export const concatContactFilterCriteria = (
	a?: ViewModels.IContactFilterCriteria[],
	b?: ViewModels.IContactFilterCriteria[]
) => {
	if (!a?.length || !b?.length) {
		// @ts-ignore
		// @ts-ignore
		return a?.length > 0 ? a : b?.length > 0 ? b : [];
	}

	return b.reduce((res, x) => {
		if (!res.find(y => equal(x, y))) {
			res.push(x);
		}
		return res;
	}, a?.slice() || []);
};

/**
 * @param criteria If passing in an observable array here, always [...criteria] because we call Array.isArray(criteria)
 *   internally
 * @see https://mobx.js.org/refguide/array.html#array-limitations-in-mobx-4-and-below
 */
export const sortContactFilterCriteria = (
	criteria: Api.IContactFilterCriteria | Api.IContactFilterCriteria[] | null | undefined
) => {
	const result: ISortedContactFilterCriteria = {
		compound: [],
		filters: [],
		searches: [],
	};

	if (criteria) {
		const addAllToResult = (key: keyof ISortedContactFilterCriteria, toAdd: ViewModels.IContactFilterCriteria[]) => {
			const collection = result[key];
			result[key] = concatContactFilterCriteria(collection, toAdd);
		};

		const addToBucket = (x: Api.IContactFilterCriteria) => {
			if (ViewModels.ContactsViewModel.SearchCriteriaProperties.indexOf(x.property) >= 0) {
				addAllToResult('searches', [x]);
			} else if (ViewModels.ContactsViewModel.SearchCriteriaFilterProperties.indexOf(x.property) >= 0) {
				addAllToResult('filters', [x]);
			}
		};

		if (!!Array.isArray(criteria) || typeof (criteria as any).slice === 'function') {
			const criteriaCopy = (criteria as Api.IContactFilterCriteria[]).slice();
			if (Array.isArray(criteriaCopy)) {
				criteriaCopy.forEach(x => {
					const sorted = sortContactFilterCriteria(x);
					if (sorted.compound.length > 0) {
						addAllToResult('compound', sorted.compound);
					} else if (sorted.filters.length > 0) {
						addAllToResult('filters', sorted.filters);
					} else if (sorted.searches.length > 0) {
						addAllToResult('searches', sorted.searches);
					}
				});
			}
		} else {
			if (criteria.criteria && criteria.criteria.length > 0) {
				let isAllSearch = true;
				let isAllFilter = true;
				criteria.criteria.forEach(x => {
					if (x.criteria && x.criteria.length > 0) {
						const sorted = sortContactFilterCriteria(x);
						isAllSearch = isAllSearch && sorted.filters.length === 0 && sorted.searches.length > 0;
						isAllFilter = isAllFilter && sorted.searches.length === 0 && sorted.filters.length > 0;
					} else if (x?.property) {
						if (ViewModels.ContactsViewModel.SearchCriteriaProperties.indexOf(x.property) >= 0) {
							isAllFilter = false;
						} else if (ViewModels.ContactsViewModel.SearchCriteriaFilterProperties.indexOf(x.property) >= 0) {
							isAllSearch = false;
						}
					}
				});
				if (isAllFilter) {
					addAllToResult('filters', [criteria]);
				} else if (isAllSearch) {
					addAllToResult('searches', [criteria]);
				} else {
					addAllToResult('compound', [criteria]);
				}
			} else if (criteria.property !== null && criteria.property !== undefined) {
				addToBucket(criteria);
			}
		}
	}

	return result;
};

export const isTagSearchContactFilterCriteria = (criteria: Api.IContactFilterCriteria): boolean => {
	return areAllCriteriaOfProperty({ criteria, property: Api.ContactFilterCriteriaProperty.Tag });
};

export const areAllCriteriaOfProperty = ({
	criteria,
	property,
}: {
	criteria: Api.IContactFilterCriteria | undefined;
	property: Api.ContactFilterCriteriaProperty;
}): boolean => {
	if (criteria) {
		if (!!criteria.criteria && criteria.criteria.length > 0) {
			return !!criteria.criteria.every(x => areAllCriteriaOfProperty({ criteria: x, property }));
		} else if (criteria.property === property) {
			return true;
		}
	}
	return false;
};

// Starts from a filter criteria and recursively tests on a predicate to find and return the first matching filter criteria.
export const findFilterCritiera = ({
	criteria,
	predicate,
}: {
	criteria: Api.IContactFilterCriteria;
	predicate: (criteria: Api.IContactFilterCriteria) => boolean;
}): Api.IContactFilterCriteria | null => {
	if (predicate(criteria)) {
		return criteria;
	}
	if (criteria.criteria && criteria.criteria.length > 0) {
		for (const c of criteria.criteria) {
			const result = findFilterCritiera({ criteria: c, predicate });
			if (result) {
				return result;
			}
		}
	}
	return null;
};

export const contactFilterCriteriaHasValue = (
	criteria: Api.IContactFilterCriteria,
	value: string,
	options?: { caseInsensitiveCompare?: boolean }
): boolean => {
	if (criteria) {
		const caseInsensitiveCompare = (!!options && !!options.caseInsensitiveCompare) || false;
		const mValue = caseInsensitiveCompare ? (value || '').toLocaleLowerCase() : value || '';
		if (!!criteria.criteria && criteria.criteria.length > 0) {
			return !!criteria.criteria.some(x => !!contactFilterCriteriaHasValue(x, value, options));
		}

		return (caseInsensitiveCompare ? (criteria.value || '').toLocaleLowerCase() : criteria.value || '') === mValue;
	}

	return false;
};

export const contactFilterCriteriaComponentCount = (criteria: Api.IContactFilterCriteria): number => {
	if (criteria) {
		if (!!criteria.criteria && criteria.criteria.length > 0) {
			return criteria.criteria.reduce((count, x) => (count += contactFilterCriteriaComponentCount(x)), 0);
		} else if (criteria.property) {
			return 1;
		}
	}

	return 0;
};

export const isIntegrationEnabledForAccount = (integration: Api.IntegrationType, account: Api.IAccount) => {
	if (integration in Api.EmailScannerId && !!account?.features?.emailScan?.enabledScanners) {
		return account.features.emailScan.enabledScanners.some(x => x === integration);
	}
	return false;
};

export const isIntegrationEnabledForUser = (
	integration: Api.IntegrationType,
	user: Api.IUser,
	account?: Api.IAccount
) => {
	if (integration in Api.EmailScannerId) {
		const enabledForAccount = account ? isIntegrationEnabledForAccount(integration, account) : true;

		let enabledForUser = true;
		if (user?.userPreferences?.optedOutEmailScanners) {
			enabledForUser = !user.userPreferences.optedOutEmailScanners.some(x => x === integration);
		}

		return enabledForAccount && enabledForUser;
	}
	return false;
};

export const getContactTitleAndCompany = (contact: Api.IContact | ViewModels.ContactViewModel) => {
	if (contact) {
		if (!!contact.jobTitle && !!contact.companyName) {
			return `${contact.jobTitle} at ${contact.companyName}`;
		}

		if (!!contact.jobTitle && !contact.companyName) {
			return contact.jobTitle;
		}

		if (!!contact.companyName && !contact.jobTitle) {
			return contact.companyName;
		}
	}
	return '';
};

export const dedupeById = <T extends { id: string | number }>(array: T[]) => {
	return array.filter((value, idx, arr) => idx === arr.findIndex(innerValue => innerValue.id === value.id));
};

export const dedupeByProvider = <T extends { provider: Api.SocialMediaType }>(array?: T[]) => {
	if (!array) {
		return [];
	}
	return array.filter((value, idx, arr) => idx === arr.findIndex(innerValue => innerValue.provider === value.provider));
};

export const isCustomResourceSelector = (resourceSelectorId: Api.ResourceSelectorId) => {
	if (!resourceSelectorId) {
		return false;
	}
	const custom = new RegExp(/custom/i);
	const selector = Object.values(Api.ResourceSelectorId)
		.filter(x => custom.test(x))
		.find(x => x === resourceSelectorId);
	return Boolean(selector);
};
interface IAutomationUtils {
	getDefaultNameForNewAutomationTemplate(trigger?: Api.IAutomationTrigger, userSession?: Api.IUserSession): string;
	steps: {
		getAutomationStepTypeForAutomationStep(step: Api.IAutomationStep): Api.AutomationStepType;
		getDefaultScheduleForAutomationStep(
			stepType: Api.AutomationStepType,
			trigger?: Api.IAutomationTrigger,
			defaultMinutesForDayOfTime?: number
		): Api.IAutomationStepSchedule;
		hasRequiredInfo(step: Api.IAutomationStep): boolean;
		supportsImmediatelyCriteriaForFirstStepInAutomation(stepType: Api.AutomationStepType): boolean;
	};
	triggers: {
		automationTriggerModelTypesWithCreateOptions: Api.ApiModelType[];
		canSetAutomationStepScheduleUsingNegativeNumberOfDays(trigger?: Api.IAutomationTrigger): boolean;
		defaults: Partial<{
			[Api.AutomationTriggerType.NewClient]: Partial<{
				[key in Api.NewClientType]: Api.INewClientAutomationTrigger;
			}>;
			[Api.AutomationTriggerType.Tag]: Api.ITagAutomationTrigger;
			[Api.AutomationTriggerType.ResourceSelector]: Partial<{
				[key in Api.ResourceSelectorId]: Api.IResourceSelectorAutomationTrigger;
			}>;
			[Api.AutomationTriggerType.NewLead]: Api.INewLeadAutomationTrigger;
			[Api.AutomationTriggerType.Texting]: Api.ITextingCampaignAutomationTrigger;
			[Api.AutomationTriggerType.Meeting]: Api.IAutomationTrigger;
		}>;
		getAutomationTriggerTitle(trigger?: Api.IAutomationTrigger, userSession?: Api.IUserSession): string;
		isResourceSelectorTrigger(trigger?: Api.IAutomationTrigger, resourceSelectorId?: Api.ResourceSelectorId): boolean;
		triggerHasOptions(trigger?: Api.IAutomationTrigger): boolean;
		triggerUsesRelativeToAnchorScheduleCriteria(trigger?: Api.IAutomationTrigger): boolean;
	};
}

export const Automations: IAutomationUtils = {
	getDefaultNameForNewAutomationTemplate: (trigger?: Api.IAutomationTrigger, userSession?: Api.IUserSession) => {
		switch (trigger?._type) {
			case Api.AutomationTriggerType.NewClient: {
				const t: Api.INewClientAutomationTrigger = trigger;
				return t.clientType?.includes(Api.NewClientType.Any)
					? 'New Client Automation'
					: t.clientType?.includes(Api.NewClientType.Commercial)
						? 'New Commercial Client Automation'
						: 'New Personal Client Automation';
			}
			case Api.AutomationTriggerType.NewLead: {
				return 'New Lead Automation';
			}
			case Api.AutomationTriggerType.Tag: {
				return 'New Tag Automation';
			}
			case Api.AutomationTriggerType.Texting: {
				return 'New Texting Automation';
			}
			case Api.AutomationTriggerType.Meeting: {
				return 'Upcoming Meeting';
			}
			case Api.AutomationTriggerType.ResourceSelector: {
				const t: Api.IResourceSelectorAutomationTrigger = trigger;
				const triggerSelector = t?.resourceSelector;
				// @ts-ignore
				const displayName = userSession?.account?.preferences?.resourceSelectorSettings?.[triggerSelector]?.displayName;

				switch (t.resourceSelector) {
					case Api.ResourceSelectorId.HappyBirthday: {
						return 'Birthday Automation';
					}
					case Api.ResourceSelectorId.Turning65: {
						return 'Turning 65 Automation';
					}
					case Api.ResourceSelectorId.Turning72: {
						return 'Turning 72 Automation';
					}
					case Api.ResourceSelectorId.Turning73: {
						return 'Turning 73 Automation';
					}
					case Api.ResourceSelectorId.PolicyRenew: {
						return 'Policy Renewal Automation';
					}
					case Api.ResourceSelectorId.FinancialReview: {
						return 'Client Review Automation';
					}
					case Api.ResourceSelectorId.TurningXX: {
						return `${displayName} Automation`;
					}
					case Api.ResourceSelectorId.Custom1:
					case Api.ResourceSelectorId.Custom2:
					case Api.ResourceSelectorId.Custom3:
					case Api.ResourceSelectorId.Custom4:
					case Api.ResourceSelectorId.Custom5: {
						return `${displayName} Automation`;
					}

					default: {
						break;
					}
				}
				break;
			}
			default: {
				break;
			}
		}
		return 'New Automation';
	},
	steps: {
		// @ts-ignore
		getAutomationStepTypeForAutomationStep: (step: Api.IAutomationStep) => {
			switch (step._type) {
				case 'ActionItemAutomationStep': {
					return Api.AutomationStepType.ActionItem;
				}
				case 'EmailAutomationStep': {
					return Api.AutomationStepType.Email;
				}
				case 'AddTagAutomationStep': {
					return Api.AutomationStepType.AddTag;
				}
				case 'RemoveTagAutomationStep': {
					return Api.AutomationStepType.RemoveTag;
				}
				case 'TextingAutomationStep': {
					return Api.AutomationStepType.Texting;
				}
				case 'NoActionAutomationStep': {
					return Api.AutomationStepType.NoAction;
				}
				case 'HandwrittenCardAutomationStep': {
					return Api.AutomationStepType.HandwrittenCard;
				}
				default: {
					return null;
				}
			}
		},
		getDefaultScheduleForAutomationStep: (
			stepType: Api.AutomationStepType,
			trigger?: Api.IAutomationTrigger,
			defaultMinutesForDayOfTime?: number
		) => {
			const schedule: Api.IAutomationStepSchedule = {};
			const defaultMinutes = defaultMinutesForDayOfTime || 0;
			const criteria =
				// @ts-ignore
				trigger?._type === Api.AutomationTriggerType.Meeting || isCustomResourceSelector(trigger?.resourceSelector)
					? Api.AutomationStepScheduleCriteria.RelativeToAnchor
					: Api.AutomationStepScheduleCriteria.AfterAutomationStart;

			// special case
			if (trigger?._type === Api.AutomationTriggerType.ResourceSelector) {
				const t = trigger as Api.IResourceSelectorAutomationTrigger;
				if (Automations.triggers.triggerUsesRelativeToAnchorScheduleCriteria(t)) {
					schedule.criteria = Api.AutomationStepScheduleCriteria.RelativeToAnchor;
					schedule.numberOfDays = t.resourceSelector !== Api.ResourceSelectorId.HappyBirthday ? -30 : 0;
					if (
						stepType === Api.AutomationStepType.Texting ||
						stepType === Api.AutomationStepType.Email ||
						Automations.triggers.isResourceSelectorTrigger(trigger, Api.ResourceSelectorId.HappyBirthday)
					) {
						schedule.numberOfHours = 9;
						schedule.numberOfMinutes = defaultMinutes;
					}
				}
			}

			if (trigger?._type === Api.AutomationTriggerType.Meeting) {
				schedule.criteria = Api.AutomationStepScheduleCriteria.RelativeToAnchor;
				schedule.numberOfDays = -1;
			}
			// @ts-ignore
			if (isCustomResourceSelector(trigger?.resourceSelector)) {
				schedule.numberOfDays = -30;
			}

			if (!schedule.criteria) {
				schedule.criteria = criteria;
				switch (stepType) {
					case Api.AutomationStepType.Email:
					case Api.AutomationStepType.AddTag:
					case Api.AutomationStepType.RemoveTag:
					case Api.AutomationStepType.Texting: {
						schedule.numberOfDays = 0;
						if (stepType === Api.AutomationStepType.Email || stepType === Api.AutomationStepType.Texting) {
							schedule.numberOfHours = 9;
							schedule.numberOfMinutes = defaultMinutes;
						}
						break;
					}
					default: {
						break;
					}
				}
			}

			if (!Object.prototype.hasOwnProperty.call(schedule, 'numberOfDays')) {
				schedule.numberOfDays =
					(Automations.triggers.canSetAutomationStepScheduleUsingNegativeNumberOfDays(trigger) ? -1 : 1) * 30;

				if (stepType === Api.AutomationStepType.Email || stepType === Api.AutomationStepType.Texting) {
					schedule.numberOfHours = 9;
					schedule.numberOfMinutes = defaultMinutes;
				}
			}

			return schedule;
		},
		hasRequiredInfo: (step: Api.IAutomationStep) => {
			switch (step?._type) {
				case 'AddTagAutomationStep':
				case 'RemoveTagAutomationStep': {
					const s = step as Api.IAddTagAutomationStep | Api.IRemoveTagAutomationStep;
					return !!s.tagName;
				}
				case 'EmailAutomationStep': {
					const s = step as ViewModels.IEmailAutomationStep;
					return Api.rawRichTextContentStateHasContent(s.content) && !!s.name;
				}
				case 'TextingAutomationStep': {
					const s = step as
						| ViewModels.ITextingAutomationStep
						| ViewModels.IEmailAutomationStep
						| ViewModels.IActionItemAutomationStep;
					// @ts-ignore
					const conditionalStepsHasRequiredInfo = Automations.steps.hasRequiredInfo(s.conditionalSteps?.Error);
					return !!conditionalStepsHasRequiredInfo && Api.rawRichTextContentStateHasContent(s.content);
				}
				case 'ActionItemAutomationStep': {
					const s = step as ViewModels.IActionItemAutomationStep;
					return Api.rawRichTextContentStateHasContent(s.content);
				}
				case 'HandwrittenCardAutomationStep': {
					const s = step as ViewModels.HandwrittenCardAutomationStep;
					const conditionalStepsHasRequiredInfo = Automations.steps.hasRequiredInfo(s.conditionalSteps?.Error);
					return (
						!!conditionalStepsHasRequiredInfo &&
						Api.rawRichTextContentStateHasContent(s.content) &&
						!!s.templateReference?.templateId
					);
				}
				default: {
					return true;
				}
			}
		},
		supportsImmediatelyCriteriaForFirstStepInAutomation: (stepType: Api.AutomationStepType) => {
			switch (stepType) {
				case Api.AutomationStepType.Email:
				case Api.AutomationStepType.AddTag:
				case Api.AutomationStepType.RemoveTag:
				case Api.AutomationStepType.Texting:
				case Api.AutomationStepType.NoAction: {
					return true;
				}
				default: {
					return false;
				}
			}
		},
	},
	triggers: {
		automationTriggerModelTypesWithCreateOptions: [Api.AutomationTriggerType.NewLead, Api.AutomationTriggerType.Tag],
		canSetAutomationStepScheduleUsingNegativeNumberOfDays: (trigger?: Api.IAutomationTrigger) => {
			if (!trigger) {
				// manual case
				return false;
			}
			let canSetBefore = true;
			if (
				[Api.AutomationTriggerType.NewLead, Api.AutomationTriggerType.NewClient, Api.AutomationTriggerType.Tag].indexOf(
					trigger._type as Api.AutomationTriggerType
				) >= 0
			) {
				canSetBefore = false;
			}

			return canSetBefore;
		},
		defaults: {
			[Api.AutomationTriggerType.NewClient]: {
				[Api.NewClientType.Any]: {
					_type: Api.AutomationTriggerType.NewClient,
					clientType: [Api.NewClientType.Any],
				},
				[Api.NewClientType.Commercial]: {
					_type: Api.AutomationTriggerType.NewClient,
					clientType: [Api.NewClientType.Commercial],
				},
				[Api.NewClientType.Personal]: {
					_type: Api.AutomationTriggerType.NewClient,
					clientType: [Api.NewClientType.Personal],
				},
			},
			[Api.AutomationTriggerType.Tag]: {
				_type: Api.AutomationTriggerType.Tag,
				// @ts-ignore
				tag: null,
			},
			[Api.AutomationTriggerType.ResourceSelector]: {
				[Api.ResourceSelectorId.HappyBirthday]: {
					_type: Api.AutomationTriggerType.ResourceSelector,
					putOnHold: true,
					resourceSelector: Api.ResourceSelectorId.HappyBirthday,
				},
				[Api.ResourceSelectorId.Turning65]: {
					_type: Api.AutomationTriggerType.ResourceSelector,
					putOnHold: true,
					resourceSelector: Api.ResourceSelectorId.Turning65,
				},
				[Api.ResourceSelectorId.Turning72]: {
					_type: Api.AutomationTriggerType.ResourceSelector,
					putOnHold: true,
					resourceSelector: Api.ResourceSelectorId.Turning72,
				},
				[Api.ResourceSelectorId.Turning73]: {
					_type: Api.AutomationTriggerType.ResourceSelector,
					putOnHold: true,
					resourceSelector: Api.ResourceSelectorId.Turning73,
				},
				[Api.ResourceSelectorId.PolicyRenew]: {
					_type: Api.AutomationTriggerType.ResourceSelector,
					putOnHold: true,
					resourceSelector: Api.ResourceSelectorId.PolicyRenew,
				},
				[Api.ResourceSelectorId.FinancialReview]: {
					_type: Api.AutomationTriggerType.ResourceSelector,
					putOnHold: true,
					resourceSelector: Api.ResourceSelectorId.FinancialReview,
				},
			},
			[Api.AutomationTriggerType.NewLead]: {
				_type: Api.AutomationTriggerType.NewLead,
				customEmailScannerIds: [],
				disableObserveSendIntervals: false,
				emailScannerIds: [],
				putOnHold: true,
			},
			[Api.AutomationTriggerType.Texting]: {
				_type: Api.AutomationTriggerType.Texting,
				acknowledgedOnlySendingToOptedIn: false,
			},
			[Api.AutomationTriggerType.Meeting]: {
				_type: Api.AutomationTriggerType.Meeting,
			},
		},
		getAutomationTriggerTitle: (trigger?: Api.IAutomationTrigger, userSession?: Api.IUserSession) => {
			switch (trigger?._type) {
				case Api.AutomationTriggerType.Texting: {
					return 'Texting Automation';
				}
				case Api.AutomationTriggerType.Tag: {
					return 'Tag Triggered Automation';
				}
				case Api.AutomationTriggerType.NewLead: {
					return 'New Leads Automation';
				}
				case Api.AutomationTriggerType.Meeting: {
					return 'Meeting Reminders';
				}
				case Api.AutomationTriggerType.NewClient: {
					const t = trigger as Api.INewClientAutomationTrigger;
					const clientType = t.clientType?.[0];
					if (clientType === Api.NewClientType.Any) {
						return 'New Client Automation';
					} else if (clientType === Api.NewClientType.Commercial) {
						return 'New Commercial Client';
					} else if (clientType === Api.NewClientType.Personal) {
						return 'New Personal Client';
					}
					break;
				}
				case Api.AutomationTriggerType.ResourceSelector: {
					const t = trigger as Api.IResourceSelectorAutomationTrigger;
					switch (t.resourceSelector) {
						case Api.ResourceSelectorId.HappyBirthday: {
							return 'Birthday Automation';
						}
						case Api.ResourceSelectorId.PolicyRenew: {
							return 'Renewal  Automation';
						}
						case Api.ResourceSelectorId.Turning65: {
							return '65th Birthday Automation';
						}
						case Api.ResourceSelectorId.Turning72: {
							return '72nd Birthday Automation';
						}
						case Api.ResourceSelectorId.Turning73: {
							return '73rd Birthday Automation';
						}
						case Api.ResourceSelectorId.FinancialReview: {
							return 'Client Review Automation';
						}

						case Api.ResourceSelectorId.TurningXX:
						case Api.ResourceSelectorId.Custom1:
						case Api.ResourceSelectorId.Custom2:
						case Api.ResourceSelectorId.Custom3:
						case Api.ResourceSelectorId.Custom4:
						case Api.ResourceSelectorId.Custom5: {
							const triggerSelector = t?.resourceSelector;
							return `${userSession?.account?.preferences?.resourceSelectorSettings?.[triggerSelector]?.displayName} Automation`;
						}
						default: {
							break;
						}
					}
					break;
				}
				default: {
					break;
				}
			}
			return 'Manually Triggered Automation';
		},
		isResourceSelectorTrigger: (trigger?: Api.IAutomationTrigger, resourceSelectorId?: Api.ResourceSelectorId) => {
			if (trigger?._type === Api.AutomationTriggerType.ResourceSelector) {
				const t: Api.IResourceSelectorAutomationTrigger = trigger;
				if (resourceSelectorId) {
					return t.resourceSelector === resourceSelectorId;
				}
				return true;
			}
			return false;
		},
		triggerHasOptions: (trigger?: Api.IAutomationTrigger) => {
			switch (trigger?._type) {
				case Api.AutomationTriggerType.Meeting:
				case Api.AutomationTriggerType.NewLead:
				case Api.AutomationTriggerType.Tag:
				case Api.AutomationTriggerType.Texting: {
					return true;
				}
				case Api.AutomationTriggerType.ResourceSelector: {
					return true;
				}
				case Api.AutomationTriggerType.NewClient: {
					return true;
				}
				default: {
					break;
				}
			}
			return false;
		},
		triggerUsesRelativeToAnchorScheduleCriteria: (trigger?: Api.IAutomationTrigger) => {
			switch (trigger?._type) {
				case Api.AutomationTriggerType.ResourceSelector: {
					const t = trigger as Api.IResourceSelectorAutomationTrigger;
					if (
						[
							Api.ResourceSelectorId.TurningXX,
							Api.ResourceSelectorId.HappyBirthday,
							Api.ResourceSelectorId.PolicyRenew,
							Api.ResourceSelectorId.Turning65,
							Api.ResourceSelectorId.Turning72,
							Api.ResourceSelectorId.Turning73,
							Api.ResourceSelectorId.FinancialReview,
							// @ts-ignore
						].indexOf(t.resourceSelector) >= 0 ||
						t.resourceSelector?.toLowerCase()?.includes('custom')
					) {
						return true;
					}
					break;
				}

				default: {
					break;
				}
			}
			return false;
		},
	},
};

export const renderConversationNames = (convo: ViewModels.ConversationViewModel) => {
	if (convo.toNumbers.length > 1) {
		return convo.toNumbers
			.map(num => {
				if (num.contact) {
					const displayName = getDisplayName(num.contact);
					if (displayName) {
						return displayName;
					}
				}
				return num.number.standard;
			})
			.join(', ');
	}

	if (convo.toNumbers[0].contact) {
		const displayName = getDisplayName(convo.toNumbers[0].contact);
		if (displayName) {
			return displayName;
		}
	}
	return convo.toNumbers[0].number.standard;
};

// TODO: write unit tests for this.
export const getLastMessageText = (lastMessage: ViewModels.ITextMessage) => {
	if (lastMessage.text) {
		return lastMessage.text;
	}

	// don't crash on empty text messages
	if (!lastMessage.media) {
		return '';
	}

	let nonImageFound = false;

	lastMessage.media.forEach(a => {
		// @ts-ignore
		const isImage = imageFileExtensions.find(e => a.mimeType.includes(e));
		if (!isImage) {
			nonImageFound = true;
		}
	});

	return `Attachment: ${lastMessage.media.length} ${nonImageFound ? 'File' : 'Image'}${
		lastMessage.media.length > 1 ? 's' : ''
	}`;
};

// TODO: write unit tests for this.
export const getTimeText = (num: number, label: string) => {
	return `${num} ${label}${num > 1 ? 's' : ''}`;
};

// TODO: write unit tests for this.
export const timeSince = (date: moment.Moment) => {
	const now = moment();
	let diff = now.diff(date, 'second');

	if (diff < 60) {
		return 'just now';
	}

	if (diff < 1200) {
		const minutes = Math.floor(diff / 60);
		return getTimeText(minutes, 'min');
	}

	diff = now.diff(date, 'hour') + 1;
	if (diff < 24) {
		return getTimeText(diff, 'hour');
	}

	diff = now.diff(date, 'day') + 1;
	if (diff < 7) {
		return getTimeText(diff, 'day');
	}

	diff = now.diff(date, 'week') + 1;
	if (diff < 4) {
		return getTimeText(diff, 'week');
	}

	diff = now.diff(date, 'month') + 1;
	if (diff < 12) {
		return getTimeText(diff, 'month');
	}

	return getTimeText(now.diff(date, 'year') + 1, 'year');
};

export const canViewTexting = (userSession: ViewModels.UserSessionContext) => {
	return userSession?.account?.features?.texting?.enabled;
};

export const Noop = () => {
	// do nothing
};

export const imageFileExtensions = ['png', 'jpg', 'jpeg', 'gif', 'webp', 'bmp'];

/** @returns The min byte count of the given configs */
export const getMaxAttachmentByteCountForEmailConfigurations = (emailConfigs: Api.IEmailProviderConfiguration[]) => {
	return (emailConfigs || []).reduce((result, config) => {
		const curr = config.maxMessageSizeInBytes || ViewModels.EmailMessageViewModel.MaxByteCount;
		return curr < result ? curr : result;
	}, ViewModels.EmailMessageViewModel.MaxByteCount);
};

export const flatten = <T>(arr: (T | readonly T[])[]): T[] => {
	const newArr: T[] = [];
	arr.forEach(x => (Array.isArray(x) ? x.forEach(y => newArr.push(y)) : newArr.push(x as T)));

	return newArr.some(x => Array.isArray(x)) ? flatten(newArr) : newArr;
};

export const timezoneMap: Api.IDictionary<string> = {
	'America/Chicago': 'CST',
	'America/Denver': 'MST',
	'America/Los_Angeles': 'PST',
	'America/New_York': 'EST',
};

export const getEmailSendLimitFromProviderConfig = (
	config: Api.IEmailProviderConfiguration,
	intervalInMinutes: number
) => {
	return (
		config?.emailSendLimits?.find(x => x.intervalInMinutes === intervalInMinutes) ||
		config?.resolvedConfiguration?.emailSendLimits?.find(x => x.intervalInMinutes === intervalInMinutes)
	);
};

export const DefaultBulkContactsRequest: Api.IBulkContactsRequest = {
	filter: {
		criteria: [{ property: Api.ContactFilterCriteriaProperty.All }, ...Api.DefaultBulkSendExcludedFilterCriteria],
	},
};

/** This should not be used for figuring out if you can text a target as part of an automation. */
export const getTextMessageCapablePhoneNumbers = (phoneNumbers?: Api.IPhoneNumber[]) => {
	const numbers = new Set<Api.IPhoneNumber>();
	if (phoneNumbers?.length) {
		const sort = (a: Api.IPhoneNumber, b: Api.IPhoneNumber) => {
			if (a.canSMS === Api.TextMessageCapability.Yes) {
				return -1;
			} else if (a.canSMS === Api.TextMessageCapability.Maybe) {
				if (b.canSMS === Api.TextMessageCapability.Yes) {
					return 1;
				}
			}
			return 0;
		};
		const filteredNumbers = phoneNumbers.filter(x => {
			return !x.label?.toLocaleLowerCase().includes('fax') && !x.label?.toLocaleLowerCase().includes('office');
		});
		const byLabelNumbers = new Set(
			filteredNumbers
				.sort((x, y) => {
					if (x.label?.toLocaleLowerCase()?.includes('mobile')) {
						return -1;
					} else if (y.label?.toLocaleLowerCase()?.includes('mobile')) {
						return 1;
					}
					return 0;
				})
				.sort(sort)
		);
		const possibleNumbers = filteredNumbers
			.filter(x => {
				return (
					!byLabelNumbers.has(x) &&
					(x.canSMS === Api.TextMessageCapability.Yes || x.canSMS === Api.TextMessageCapability.Maybe)
				);
			})
			.sort(sort);
		byLabelNumbers.forEach(x => numbers.add(x));
		possibleNumbers.forEach(x => numbers.add(x));
	}

	return Array.from(numbers);
};

export const dateWithoutUTCTime = (d: string) => new Date(d.toString().slice(0, -1));

export const dateWithTimeToUTCString = (d: Date, h = 0, m = 0, s = 0) =>
	moment(new Date(d.setUTCHours(h, m, s))).toISOString();

export const apiIntegrationSources: Api.IntegrationSources[] = Object.values(Api.IntegrationSources).filter(
	x => x !== Api.IntegrationSources.CsvImport
);

export const integrationSources = Object.values(Api.IntegrationSources).filter(
	x => x !== Api.IntegrationSources.CsvImport
) as string[];

export const isAMSIntegration = (source: Api.IntegrationSources) => {
	const AMSIntegrationSources = [
		Api.IntegrationSources.AMS360,
		Api.IntegrationSources.HawkSoft,
		Api.IntegrationSources.EpicCsv,
		Api.IntegrationSources.EzLynxCsv,
		Api.IntegrationSources.QQCatalyst,
	];
	return AMSIntegrationSources.includes(source);
};
